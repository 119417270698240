// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactHelmet from "react-helmet";
import * as Errors$Weblab from "../../service/Errors.bs.js";
import * as Belt_HashMapInt from "rescript/lib/es6/belt_HashMapInt.js";
import * as Notebook$Weblab from "../../components/Notebook.bs.js";
import * as NotebookBase$Weblab from "../../components/NotebookBase.bs.js";
import * as NotebookFormat$Weblab from "../../service/NotebookFormat.bs.js";
import Chartjs_lineJson from "/src/notebooks/chartjs_line.json";

var x = NotebookFormat$Weblab.convertNotebookJSONtoRESync(Chartjs_lineJson);

var welcomeNotebook;

if (x.TAG === /* Ok */0) {
  welcomeNotebook = x._0;
} else {
  alert(Errors$Weblab.getErrorMessage(x._0));
  welcomeNotebook = Belt_Array.make(1, NotebookBase$Weblab.defaultCell(undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var initialIndices = Belt_List.fromArray(Belt_Array.mapWithIndex(welcomeNotebook, (function (i, param) {
            return i;
          })));

var initialCells = Belt_HashMapInt.fromArray(Belt_Array.mapWithIndex(welcomeNotebook, (function (i, e) {
            return [
                    i,
                    e
                  ];
          })));

function ChartjsLine(Props) {
  var $$location = Props.location;
  return React.createElement(React.Fragment, undefined, React.createElement(ReactHelmet.Helmet, {
                  children: React.createElement("meta", {
                        content: "Create line plots with Chart.js.",
                        name: "description"
                      })
                }), React.createElement(Notebook$Weblab.make, {
                  location: $$location,
                  name: "chartjs_line_plot.ijsnb",
                  initialIndices: initialIndices,
                  initialCells: initialCells
                }));
}

ChartjsLine.displayName = "TFJSExample1";

var make = ChartjsLine;

var $$default = ChartjsLine;

export {
  welcomeNotebook ,
  initialIndices ,
  initialCells ,
  make ,
  $$default ,
  $$default as default,
  
}
/* x Not a pure module */
